import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api'
import localStorageService from 'services/storage'
import { DUMMY_ZONE_NAME } from './constants'

const SLICE_KEY = 'global'

const DEFAULT_ALERT_TIMEOUT = 3000

export const configureMap = createAsyncThunk(`${SLICE_KEY}/configureMap`, async (args, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const { mapBaseUrl, mapZoomDelta } = await apiService.configureMap()

  dispatch(setGlobalLoading(false))

  return { mapBaseUrl, mapZoomDelta }
})

export const configurePlatform = createAsyncThunk(`${SLICE_KEY}/configurePlatform`, async (args, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const { platform } = await apiService.configurePlatform()

  dispatch(setGlobalLoading(false))
  return { platform }
})

export const configureStaticFiles = createAsyncThunk(
  `${SLICE_KEY}/configureStaticFiles`,
  async (args, { dispatch }) => {
    dispatch(setGlobalLoading(true))

    const { staticFilesUrl } = await apiService.configureStaticFiles()

    dispatch(setGlobalLoading(false))
    return { staticFilesUrl }
  },
)

export const fetchUserRoles = createAsyncThunk(`${SLICE_KEY}/fetchUserRoles`, async (args, { dispatch }) => {
  const { roles } = await apiService.userRoles()
  return { roles }
})

export const fetchZones = createAsyncThunk(`${SLICE_KEY}/fetchZones`, async (args, { dispatch }) => {
  dispatch(setIsLoadingZones(true))

  const { zones } = await apiService.zones()

  dispatch(setIsLoadingZones(false))

  return { zones }
})

export const fetchGroups = createAsyncThunk(`${SLICE_KEY}/fetchGroups`, async (args, { dispatch }) => {
  dispatch(setIsLoadingGroups(true))

  const { groups } = await apiService.groups()

  dispatch(setIsLoadingGroups(false))

  return { groups }
})

export const fetchOrganizations = createAsyncThunk(`${SLICE_KEY}/fetchOrganizations`, async (args, { dispatch }) => {
  dispatch(setIsLoadingOrganizations(true))

  const { organizations } = await apiService.organizations()

  dispatch(setIsLoadingOrganizations(false))

  return { organizations }
})

export const fetchUsers = createAsyncThunk(`${SLICE_KEY}/fetchUsers`, async (args, { dispatch }) => {
  dispatch(setIsLoadingUsers(true))

  const { users } = await apiService.users()

  dispatch(setIsLoadingUsers(false))

  return { users }
})

export const fetchPlatforms = createAsyncThunk(`${SLICE_KEY}/fetchPlatforms`, async (args, { dispatch }) => {
  dispatch(setIsLoadingPlatforms(true))

  const { platforms } = await apiService.platforms()

  dispatch(setIsLoadingPlatforms(false))

  return { platforms }
})

export const fetchVerticals = createAsyncThunk(`${SLICE_KEY}/fetchVerticals`, async (args, { dispatch }) => {
  dispatch(setIsLoadingPlatforms(true))

  const { verticals } = await apiService.verticals()

  dispatch(setIsLoadingPlatforms(false))

  return { verticals }
})

export const fetchConfigurationGlobals = createAsyncThunk(
  `${SLICE_KEY}/fetchConfigurationGlobals`,
  async (args, { dispatch }) => {
    const asyncMethods = [fetchZones, fetchOrganizations, fetchUsers, fetchPlatforms, fetchGroups]

    await Promise.all(asyncMethods.map(func => dispatch(func())))
  },
)

export const fetchTransmissions = createAsyncThunk(`${SLICE_KEY}/fetchTransmissions`, async () => {
  const { transmissions } = await apiService.dashboardTransmissions()
  return { transmissions }
})

export const fetchCurrentUser = createAsyncThunk(`${SLICE_KEY}/fetchCurrentUser`, async (args, { dispatch }) => {
  try {
    const { currentUser, availableZones } = await apiService.currentUser()
    await dispatch(setCurrentUser({ currentUser, availableZones }))
    if (currentUser?.email) {
      dispatch(setCurrentUserStatus(CURRENT_USER_STATUS.LOGGED_IN))
    }
    localStorageService.clearReturnUrl()
  } catch (e) {
    dispatch(setCurrentUserStatus(CURRENT_USER_STATUS.LOGGED_OUT))
  }
})

const setLoginStates = async ({ currentUser, availableZones, platforms }, { dispatch, getState }) => {
  await dispatch(setCurrentUser({ currentUser, availableZones }))
  dispatch(setCurrentUserStatus(CURRENT_USER_STATUS.LOGGED_IN))
  await dispatch(fetchConfigurationGlobals())

  const { organizations } = getState().global
  await dispatch(setCurrentOrganization({ currentUser, organizations }))

  return { platforms }
}

export const dispatchLoginStates = createAsyncThunk(
  `${SLICE_KEY}/login`,
  async ({ currentUser, availableZones, platforms }, { getState, dispatch }) => {
    setLoginStates({ currentUser, availableZones, platforms }, { dispatch, getState })
  },
)

export const login = createAsyncThunk(
  `${SLICE_KEY}/login`,
  async ({ email, password }, { getState, dispatch, rejectWithValue }) => {
    await dispatch(setCurrentZone({}))
    try {
      const { currentUser, availableZones, platforms } = await apiService.login(email, password)
      if (currentUser.isProvisional) {
        return currentUser
      }
      return setLoginStates({ currentUser, availableZones, platforms }, { dispatch, getState })
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  },
)

export const logout = createAsyncThunk(`${SLICE_KEY}/logout`, async (args, { dispatch }) => {
  await apiService.logout()
  dispatch(setCurrentUserStatus(CURRENT_USER_STATUS.LOGGED_OUT))
  dispatch(setIsUserPlatformInitialized({ isInitialized: false }))
  dispatch(setUserPlatform({ platform: null }))
  await dispatch(setCurrentZone({}))
  await dispatch(setCurrentUser({}))

  dispatch(addAlert({ type: 'success', message: 'Logged out' }))

  localStorageService.clearAllFilters()
})

export const addAlert = createAsyncThunk(`${SLICE_KEY}/addAlert`, async (alert, { dispatch }) => {
  const alertId = Date.now()
  dispatch(createAlert({ id: alertId, ...alert }))

  setTimeout(() => {
    dispatch(removeAlert({ id: alertId }))
  }, DEFAULT_ALERT_TIMEOUT)
})

export const submitUpdatePassword = createAsyncThunk(
  `${SLICE_KEY}/submitUpdatePassword`,
  async ({ newPassword, currentPassword }, { getState, dispatch }) => {
    dispatch(setGlobalLoading(true))

    const { currentUser } = getState().global
    try {
      await apiService.updatePassword(currentUser, currentPassword, newPassword)
      dispatch(
        addAlert({
          type: 'success',
          message: 'Password was changed successfully',
        }),
      )
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: err?.response?.data?.message || 'An error occurred while changing password',
        }),
      )
    } finally {
      dispatch(setGlobalLoading(false))
    }
    return true
  },
)

export const resetUserPassword = createAsyncThunk(`${SLICE_KEY}/resetUserPassword`, async ({ user }, { dispatch }) => {
  dispatch(setGlobalLoading(true))
  await apiService.changePassword(user, user.newPassword)

  dispatch(setGlobalLoading(false))
  dispatch(addAlert({ type: 'success', message: "This user's password was reset" }))
  return true
})

export const fetchGraphData = createAsyncThunk(
  `${SLICE_KEY}/fetchGraphData`,
  async ({ entityId, zoneEndpoint }, { dispatch }) => {
    dispatch(setGlobalLoading(true))
    const graphData = await apiService.fetchGraphData(entityId, zoneEndpoint)
    if (graphData) dispatch(setGraphData({ graphData }))
    dispatch(setGlobalLoading(false))
  },
)

// TODO convert to Typescript enum
export const CURRENT_USER_STATUS = {
  PENDING: 'pending',
  LOGGED_IN: 'loggedIn',
  LOGGED_OUT: 'loggedOut',
  PROVISIONAL: 'provisional',
}

export const initialState = {
  mapBaseUrl: '',
  platform: '',
  staticFilesUrl: '',
  mapZoomDelta: 1,
  currentUser: {},
  currentUserStatus: CURRENT_USER_STATUS.PENDING,
  availableZones: [],
  isGlobalLoading: false,
  isLoggingIn: false,
  isInitialized: false,
  alerts: {},
  userRoles: [],
  isLoadingZones: false,
  allZones: [],
  currentZone: null,
  dashboardTransmissions: [],
  isLoadingOrganizations: false,
  organizations: [],
  currentOrganization: {},
  isLoadingUsers: false,
  users: [],
  isLoadingPlatforms: false,
  platforms: [],
  verticals: [],
  userPlatform: null,
  isUserPlatformInitialized: false,
  graphData: { nodes: [], edges: [] },
  groups: [],
  isLoadingGroups: false,
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setGlobalLoading(state, { payload: isLoading = false }) {
      state.isGlobalLoading = isLoading
    },
    setIsLoggingIn(state, { payload: isLoggingIn = false }) {
      state.isLoggingIn = isLoggingIn
    },
    setCurrentUser(state, { payload = {} }) {
      const { currentUser, availableZones = [] } = payload
      state.currentUser = currentUser
      if (availableZones.length === 1) {
        const [onlyAvailableZone] = availableZones

        state.currentZone = onlyAvailableZone
        state.availableZones = []
        return
      }

      state.availableZones = availableZones

      const dummyZone = availableZones.find(({ name }) => name === DUMMY_ZONE_NAME)

      state.currentZone = dummyZone
    },
    setCurrentUserStatus(state, { payload }) {
      state.currentUserStatus = payload
    },
    setCurrentOrganization(state, { payload = {} }) {
      const { currentUser, organizations } = payload

      const { organizationId } = currentUser
      if (!organizationId) return

      const currentOrganization = organizations.find(({ id }) => id === organizationId)

      if (currentOrganization) state.currentOrganization = currentOrganization
    },
    setIsLoadingZones(state, { payload: isLoading = false }) {
      state.isLoadingZones = isLoading
    },
    setIsLoadingOrganizations(state, { payload: isLoading = false }) {
      state.isLoadingOrganizations = isLoading
    },
    setIsLoadingUsers(state, { payload: isLoading = false }) {
      state.isLoadingUsers = isLoading
    },
    setIsLoadingPlatforms(state, { payload: isLoading = false }) {
      state.isLoadingPlatforms = isLoading
    },
    setIsLoadingGroups(state, { payload: isLoading = false }) {
      state.isLoadingGroups = isLoading
    },
    setIsInitialized(state, { payload: isInitialized = false }) {
      state.isInitialized = isInitialized
    },
    createAlert(state, { payload = {} }) {
      const { id } = payload
      state.alerts[id] = payload
    },
    removeAlert(state, { payload = {} }) {
      const { id } = payload
      delete state.alerts[id]
    },
    setCurrentZone(state, { payload = {} }) {
      const { zone } = payload
      state.currentZone = zone
    },
    onOrganizationsPageUnmount(state) {
      state.isLoadingZones = true
      state.isLoadingOrganizations = true
      state.isLoadingUsers = true
    },
    setUserPlatform(state, { payload = {} }) {
      const { platform } = payload
      state.userPlatform = platform
    },
    setIsUserPlatformInitialized(state, { payload = {} }) {
      const { isInitialized } = payload
      state.isUserPlatformInitialized = isInitialized
    },
    setGraphData(state, { payload = {} }) {
      const { graphData } = payload
      state.graphData = graphData
    },
  },
  extraReducers: builder => {
    builder
      .addCase(configureMap.fulfilled, (state, { payload = {} }) => {
        const { mapBaseUrl, mapZoomDelta } = payload
        state.mapBaseUrl = mapBaseUrl
        state.mapZoomDelta = parseFloat(mapZoomDelta)
      })
      .addCase(configurePlatform.fulfilled, (state, { payload = {} }) => {
        const { platform } = payload
        state.platform = platform
      })
      .addCase(configureStaticFiles.fulfilled, (state, { payload = {} }) => {
        const { staticFilesUrl } = payload
        state.staticFilesUrl = staticFilesUrl
      })
      .addCase(fetchUserRoles.fulfilled, (state, { payload = {} }) => {
        const { roles } = payload
        state.userRoles = roles
      })
      .addCase(fetchZones.fulfilled, (state, { payload = {} }) => {
        const { zones } = payload
        state.allZones = zones
      })
      .addCase(fetchGroups.fulfilled, (state, { payload = {} }) => {
        const { groups } = payload
        state.allGroups = groups
      })
      .addCase(fetchOrganizations.fulfilled, (state, { payload = {} }) => {
        const { organizations } = payload
        state.organizations = organizations
      })
      .addCase(fetchUsers.fulfilled, (state, { payload = {} }) => {
        const { users } = payload
        state.users = users
      })
      .addCase(fetchPlatforms.fulfilled, (state, { payload = {} }) => {
        const { platforms } = payload
        state.platforms = platforms
      })
      .addCase(fetchVerticals.fulfilled, (state, { payload = {} }) => {
        const { verticals } = payload
        state.verticals = verticals
      })
      .addCase(fetchTransmissions.fulfilled, (state, { payload = {} }) => {
        const { transmissions } = payload
        state.dashboardTransmissions = transmissions
      })
      .addCase('organizations/submitUser/rejected', state => {
        state.isGlobalLoading = false
      })
  },
})

export const {
  setIsInitialized,
  setGlobalLoading,
  setIsLoggingIn,
  setIsLoadingZones,
  setIsLoadingGroups,
  setIsLoadingOrganizations,
  setIsLoadingUsers,
  setIsLoadingPlatforms,
  removeAlert,
  createAlert,
  setCurrentZone,
  setCurrentUser,
  setCurrentUserStatus,
  setCurrentOrganization,
  onOrganizationsPageUnmount,
  setUserPlatform,
  setIsUserPlatformInitialized,
  setGraphData,
} = slice.actions

export const globalReducer = slice.reducer
