import { useTheme } from '@mui/material/styles'

export const useTableStyles = (calculatedHeight?: string | number) => {
  const theme = useTheme()
  return {
    muiTableContainerProps: {
      ...theme.mixins.customScrollBar(),
      height: calculatedHeight ? calculatedHeight : { md: '55vh', xlplus: '63vh', xxxl: '73vh' },
    },
    muiTablePaperProps: {
      padding: '4px',
      borderRadius: '6px',
      width: 'calc(100vw - 132px)',
    },

    muiTableBodyRowProps: {
      backgroundColor: '#f4f4f6',
      cursor: 'pointer',
    },
    muiTableHeadCellProps: {
      padding: '10px',
      border: 'none',
      alignItems: 'center',
      '.Mui-TableHeadCell-Content': {
        justifyContent: 'flex-start',
      },
    },
    muiTableBodyCellProps: {
      padding: '10px',
    },
  }
}
