import React from 'react'
import { FormattedNumber } from 'react-intl'

interface EthIconStyle {
  height: string
  marginBottom: string
  paddingRight: string
}

export interface IIncome {
  value: number | string
  currency?: string
  isFormatted?: boolean
  className?: string
  ethIconStyle?: EthIconStyle
}

export const getAbbreviatedNumber = (number_input: number | string) => {
  let originalNumber: number
  let number: number
  if (typeof number_input === 'string') {
    originalNumber = parseFloat(number_input)
    number = parseFloat(number_input)
  } else {
    originalNumber = number_input
    number = number_input
  }

  // if the number is between -1000 to 1000, return it as is
  if ((number > 0 && number < 1000) || (number > -1000 && number < 0)) {
    return number % 1 !== 0
      ? { finalNumber: parseFloat(number.toFixed(2)), unit: '' }
      : { finalNumber: number, unit: '' }
  }

  // if the number is negative, make it positive
  if (number < 0) number = -number

  // abbreviate the number
  let abbreviatedNumber = number
  const units = ['K', 'M', 'B', 'T']
  let unit = ''
  let i = 0
  while (abbreviatedNumber >= 1000 && i < units.length) {
    abbreviatedNumber /= 1000
    unit = units[i]
    // eslint-disable-next-line no-plusplus
    i++
  }

  // return the abbreviated number as a string with the appropriate unit
  if (originalNumber < 0) abbreviatedNumber = -abbreviatedNumber
  const finalNumber: number =
    abbreviatedNumber % 1 !== 0 ? parseFloat(abbreviatedNumber?.toFixed(2)) : abbreviatedNumber
  return { finalNumber, unit }
}

export const getAbbreviatedNumberString = (num: number) => {
  const parsed = getAbbreviatedNumber(num)

  return `${parsed.finalNumber}${parsed.unit}`
}

const Income = ({ value, currency = 'USD', isFormatted = true, className, ethIconStyle }: IIncome) => {
  let formattedValue: number
  if (typeof value === 'string') {
    formattedValue = parseFloat(value)
  } else {
    formattedValue = value
  }
  let suffix = ''

  if (isFormatted) {
    const { finalNumber, unit } = getAbbreviatedNumber(value)
    formattedValue = finalNumber
    suffix = unit
  }

  //  eth: height: 15px marginBottom: 4px paddingRight: 7px
  return (
    <span className={className}>
      {currency === 'ETH' ? (
        <span>
          <img src={require('./assets/eth.svg')} style={ethIconStyle} />
          <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={2} value={formattedValue} />
        </span>
      ) : (
        <FormattedNumber style='currency' minimumFractionDigits={0} value={formattedValue} currency={currency} />
      )}
      <span>{suffix}</span>
    </span>
  )
}

export default Income
