import { SxProps } from '@mui/material'
import commonStyles from '../commonStyles'

const title: SxProps = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
}

const subTitle: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '12px', lg: '13px', xl: '14px' },
  fontWeight: 700,
  color: '#7E8BA6',
  textTransform: 'uppercase',
}

const tableCell: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '14px', lg: '15px', xl: '16px' },
  fontWeight: 400,
}

const namesCell: SxProps = {
  padding: { md: '8px 0', lg: '10px 0', xl: '12px 0' },
  borderBottom: '1px solid rgba(218, 219, 223, 1)',
  '&:last-child': {
    border: 'none',
  },
}

const iconStyles = { md: 26, lg: 28, xl: 32 }

export const tooltipText: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(28, 40, 66, 1)',
  maxWidth: '250px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export const moreItemsLabel: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(28, 40, 66, 1)',
}

const entityNamesBadge: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '1px',
  textAlign: 'left',
  color: '#2257C5',
  backgroundColor: '#9FBEFF63',
  border: '1px solid #2257C5',
  borderRadius: '4px',
}

const styles = {
  title,
  paperContainer: commonStyles.paperContainer,
  dataGrid: commonStyles.dataGrid,
  subTitle,
  tableCell,
  namesCell,
  iconStyles,
  tooltipText,
  moreItemsLabel,
  entityNamesBadge,
}

export default styles
