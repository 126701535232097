import React, { useEffect, useMemo, useState } from 'react'

import PaginationCounters from 'components/verticals/TableActionsBar/PaginationCounters'
import ExportCSVButton from 'components/verticals/TableActionsBar/ExportCSVButton'
import DashedLine from './DashedLine'

import messages from './messages'

import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { FormattedMessage } from 'react-intl'

import { downloadCSV } from 'utils/downloadCSV'
import { _source } from 'utils/csvConfig'
import { exportEntitiesCSV } from 'services/verticalsTablesApi'
import SearchBar from './SearchBar/SearchBar'
import Icon from 'components/Icon'
import Button from 'components/Button'
import styles from './TableActionsBar.scss'
import FilterDrawer from 'components/FilterDrawer/FilterDrawer'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import { MRT_ColumnDef, MRT_RowData, MRT_SortingState } from 'material-react-table'
import { ISelectedColumn } from '../VerticalTableBase/types'
import ColumnPicker from './ColumnPicker/ColumnPicker'
import { FilterOptions } from 'containers/CDTFATablePage/types'
import { useSelectedRows } from 'store/selectedRows/SelectedRowsContext'

const containerStyles: SxProps = {
  flexDirection: 'row',
  paddingBlockEnd: '1.125rem',
  justifyContent: 'space-between',
  alignItems: 'center',
}

interface TableActionBarsProps<T extends MRT_RowData> {
  countMessages: FormattedMessage.MessageDescriptor
  displayedCount: number | null
  totalCount: number | null
  zoneEndpoint: string
  csvFileName: string
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  type: string
  withExport?: boolean
  activeFilters?: Filter[]
  setActiveFilters?: React.Dispatch<React.SetStateAction<Filter[]>>
  mergedColumns?: MRT_ColumnDef<T>[]
  selectedColumns?: ISelectedColumn[]
  setSelectedColumns?: React.Dispatch<React.SetStateAction<ISelectedColumn[]>>
  allColumns?: MRT_ColumnDef<T>[]
  filtersInitialState?: Filter[]
  filterOptions?: FilterOptions
  filterAdditionalData?: FilterAdditionalData[]
  emptyColumns?: string[] | null
  sorting?: MRT_SortingState
}

const TableActionsBars = <T extends MRT_RowData>({
  countMessages,
  displayedCount,
  totalCount,
  zoneEndpoint,
  csvFileName,
  searchValue,
  setSearchValue,
  activeFilters,
  setActiveFilters,
  mergedColumns,
  selectedColumns,
  setSelectedColumns,
  allColumns,
  filtersInitialState,
  filterOptions,
  type,
  withExport = true,
  filterAdditionalData,
  emptyColumns,
  sorting,
}: TableActionBarsProps<T>) => {
  const [isExportDisabled, setIsExportDisabled] = useState(false)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [isColumnDropdownOpen, setIsColumnDropdownOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // State for loading indicator

  const { selectedRows } = useSelectedRows()

  const columnsOptions = useMemo(() => {
    return (
      allColumns?.map((column: any) => ({
        value: column.id,
        label: column.header,
      })) || []
    )
  }, [allColumns])

  useEffect(() => {
    if (!mergedColumns || mergedColumns.length === 0) return
    if (setSelectedColumns) {
      setSelectedColumns(columnsOptions.filter((el: ISelectedColumn) => !emptyColumns?.includes(el.value as string)))
    }
  }, [columnsOptions, mergedColumns, setSelectedColumns, emptyColumns])

  const { trackEvent } = useMatomo()

  const onCSVExport = async () => {
    setIsExportDisabled(true)
    setIsLoading(true)
    let entityCSV

    try {
      if (selectedRows.length > 0) {
        const selectedIds = selectedRows.map((row: any) => row.id)
        entityCSV = await exportEntitiesCSV(zoneEndpoint, _source, type, selectedIds)
      } else {
        entityCSV = await exportEntitiesCSV(zoneEndpoint, _source, type, undefined, searchValue, sorting)
      }

      downloadCSV(entityCSV, csvFileName)
      trackEvent({ category: 'Export', action: 'User requested for entity CSV' })
    } catch (error) {
      console.error('Error exporting CSV:', error)
    } finally {
      setIsLoading(false)
      setIsExportDisabled(false)
    }
  }

  const handleFilterButtonClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const handleColumnButtonClick = () => {
    setIsColumnDropdownOpen(!isColumnDropdownOpen)
  }

  return (
    <>
      <Stack sx={containerStyles} className={styles.tableActionsBar}>
        <PaginationCounters countMessages={countMessages} displayedCount={displayedCount} totalCount={totalCount} />
        <DashedLine />
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
          {activeFilters && setActiveFilters && (
            <Button className={styles.actionsBarButton} onClick={handleFilterButtonClick}>
              <Icon size='standard' name='filterBlue' />
            </Button>
          )}
          {selectedColumns && setSelectedColumns && (
            <div style={{ position: 'relative' }}>
              <Button className={styles.actionsBarButton} onClick={handleColumnButtonClick}>
                <Icon size='standard' name='columnsBlue' />
              </Button>
              <ColumnPicker
                columnsOptions={columnsOptions}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                isColumnDropdownOpen={isColumnDropdownOpen}
              />
            </div>
          )}
          {withExport && (
            <ExportCSVButton
              exportMessages={messages.export}
              isExportDisabled={isExportDisabled}
              onCSVExport={onCSVExport}
            />
          )}
        </div>
        {activeFilters && setActiveFilters && filtersInitialState && filterOptions && (
          <FilterDrawer
            open={isFilterDrawerOpen}
            onClose={() => setIsFilterDrawerOpen(false)}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filtersInitialState={filtersInitialState}
            filterOptions={filterOptions}
            filterAdditionalData={filterAdditionalData}
          />
        )}
      </Stack>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default TableActionsBars
