import { StylesheetCSS as CSStylesheet } from 'cytoscape'

const instagram = require('./assets/icons/graphNodes/instagram.svg')
const airbnb = require('./assets/icons/graphNodes/airbnb.svg')
const linktree = require('./assets/icons/graphNodes/linktree.svg')
const opensea = require('./assets/icons/graphNodes/opensea.svg')
const openseaCollection = require('./assets/icons/graphNodes/openseaCollection.svg')
const twitter = require('./assets/icons/graphNodes/twitter.svg')
const cryptoWallet = require('./assets/icons/graphNodes/cryptoWallet.svg')
const phoneNumber = require('./assets/icons/graphNodes/phoneNumber.svg')
const email = require('./assets/icons/graphNodes/email.svg')
const gmaps = require('./assets/icons/graphNodes/gmaps.svg')
const linkedin = require('./assets/icons/graphNodes/linkedin.svg')
const facebook = require('./assets/icons/graphNodes/facebook.svg')
const website = require('./assets/icons/graphNodes/website.svg')
const location = require('./assets/icons/graphNodes/location.svg')
const telegram = require('./assets/icons/graphNodes/telegram.svg')
const youtube = require('./assets/icons/graphNodes/youtube.svg')
const onlyfans = require('./assets/icons/graphNodes/onlyfans.svg')
const tiktok = require('./assets/icons/graphNodes/tiktok.svg')
const snapchat = require('./assets/icons/graphNodes/snapchat.svg')
const fiverr = require('./assets/icons/graphNodes/fiverr.svg')
const upwork = require('./assets/icons/graphNodes/upwork.svg')
const tradivisor = require('./assets/icons/graphNodes/tradivisor.svg')
const yelp = require('./assets/icons/graphNodes/yelp.svg')
const locationGeohash = require('./assets/icons/graphNodes/location_geohash.svg')
const barBusiness = require('./assets/icons/graphNodes/bar_business.svg')
const forsquare = require('./assets/icons/graphNodes/forsquare.svg')
const weedmaps = require('./assets/icons/graphNodes/weedmaps.svg')
const opentable = require('./assets/icons/graphNodes/opentable.svg')
const license = require('./assets/icons/graphNodes/license.svg')

export const elemColors: { [key: string]: string } = {
  AIRBNB: '#ff6161',
  FACEBOOK: '#3B5998',
  LOCATION: '#b56576',
  TWITTER: '#03a9f4',
  BLOCKCHAIN_WALLET: '#666058',
  PHONE_NUMBER: '#5d7281',
  EMAIL: '#258F96',
  GMAPS: '#232323',
  INSTAGRAM: '#C833D9',
  OPENSEA_ACCOUNT: '#2081e2',
  OPENSEA_COLLECTION: '#2081e2',
  LINKTREE: '#31d08b',
  LINKEDIN: '#0077b5',
  WEBSITE: '#1c3568',
  TELEGRAM: '#2096D4',
  ETSY_SHOP: '#F25800',
  YOUTUBE: '#FF6464',
  ONLYFANS: '#00AFF0',
  TIKTOK: '#82D8D6',
  SNAPCHAT: '#272727',
  FIVERR: '#1FDD84',
  UPWORK: '#A1EA83',
  OTHER: '#e63946',
  EDGE: '#A5ABB6',
  TRIPADVISOR_BUSINESS: '#00AF87',
  YELP_BUSINESS: '#BB0000',
  LOCATION_GEOHASH: '#979695',
  BAR_BUSINESS: '#153554',
  FOURSQUARE_BUSINESS: '#EF4977',
  WEEDMAPS_BUSINESS: '#252935',
  DOC_LICENSE: '#22734D',
  OPENTABLE: '#DA3743',
}

const defaultStyle: CSStylesheet[] = [
  {
    selector: 'node',
    css: {
      'font-size': 3,
      shape: 'ellipse',
      width: 25,
      height: 25,
      'text-valign': 'center',
      'text-halign': 'center',
      label: 'data(type)',
      'background-color': '#fafafa',
      'border-color': elemColors.OTHER,
      'border-width': '1px',
      'background-fit': 'cover',
      'background-image-containment': 'over',
    },
  },
  {
    selector: 'node[type="YOUTUBE"]',
    css: {
      'border-color': elemColors.YOUTUBE,
      'background-image': youtube,
      label: '',
    },
  },
  {
    selector: 'node[type="ONLYFANS"]',
    css: {
      'border-color': elemColors.ONLYFANS,
      'background-image': onlyfans,
      label: '',
    },
  },
  {
    selector: 'node[type="TIKTOK"]',
    css: {
      'border-color': elemColors.TIKTOK,
      'background-image': tiktok,
      label: '',
    },
  },
  {
    selector: 'node[type="SNAPCHAT"]',
    css: {
      'border-color': elemColors.SNAPCHAT,
      'background-image': snapchat,
      label: '',
    },
  },
  {
    selector: 'node[type="ETSY_SHOP"]',
    css: {
      'border-color': elemColors.ETSY_SHOP,
      label: '',
    },
  },
  {
    selector: 'node[type="FACEBOOK"]',
    css: {
      'border-color': elemColors.FACEBOOK,
      'background-image': facebook,
      label: '',
    },
  },
  {
    selector: 'node[type="AIRBNB"]',
    css: {
      'border-color': elemColors.AIRBNB,
      'background-image': airbnb,
      label: '',
    },
  },
  {
    selector: 'node[type="LOCATION"]',
    css: {
      'border-color': elemColors.LOCATION,
      'background-image': location,
      label: 'data(geolocator_res_country)',
      color: 'white',
      'font-family': 'Source Sans Pro, sans-serif',
      'font-size': '4px',
      'text-margin-y': 4,
      'text-wrap': 'wrap',
      // FIXME: line break if name is too long
    },
  },
  {
    selector: 'node[type="TWITTER"]',
    css: {
      'border-color': elemColors.TWITTER,
      'background-image': twitter,
      label: '',
    },
  },
  {
    selector: 'node[type="INSTAGRAM"]',
    css: {
      'border-color': elemColors.INSTAGRAM,
      'background-image': instagram,
      label: '',
    },
  },
  {
    selector: 'node[type="OPENSEA_COLLECTION"]',
    css: {
      'border-color': elemColors.OPENSEA_COLLECTION,
      'background-image': openseaCollection,
      label: '',
    },
  },
  {
    selector: 'node[type="OPENSEA_ACCOUNT"]',
    css: {
      'border-color': elemColors.OPENSEA_ACCOUNT,
      'background-image': opensea,
      label: '',
    },
  },
  {
    selector: 'node[type="LINKTREE"]',
    css: {
      'border-color': elemColors.LINKTREE,
      'background-image': linktree,
      label: '',
    },
  },
  {
    selector: 'node[type="BLOCKCHAIN_WALLET"]',
    css: {
      'border-color': elemColors.BLOCKCHAIN_WALLET,
      'background-image': cryptoWallet,
      label: '',
    },
  },
  {
    selector: 'node[type="WEBSITE"]',
    css: {
      'border-color': elemColors.WEBSITE,
      'background-image': website,
      label: '',
    },
  },
  {
    selector: 'node[type="TELEGRAM"]',
    css: {
      'border-color': elemColors.TELEGRAM,
      'background-image': telegram,
      label: '',
    },
  },
  {
    selector: 'node[type="PHONE"]',
    css: {
      'border-color': elemColors.PHONE_NUMBER,
      'background-image': phoneNumber,
      label: '',
    },
  },
  {
    selector: 'node[type="EMAIL"]',
    css: {
      'border-color': elemColors.EMAIL,
      'background-image': email,
      label: '',
    },
  },
  {
    selector: 'node[type="LINKEDIN"]',
    css: {
      'border-color': elemColors.LINKEDIN,
      'background-image': linkedin,
      label: '',
    },
  },
  {
    selector: 'node[type="GMAPS"]',
    css: {
      'border-color': elemColors.GMAPS,
      'background-image': gmaps,
      color: '#fff',
      label: '',
    },
  },
  {
    selector: 'node[type="UPWORK"]',
    css: {
      'border-color': elemColors.UPWORK,
      'background-image': upwork,
      label: '',
    },
  },
  {
    selector: 'node[type="FIVERR"]',
    css: {
      'border-color': elemColors.FIVERR,
      'background-image': fiverr,
      label: '',
    },
  },
  {
    selector: 'node[type="TRIPADVISOR_BUSINESS"]',
    css: {
      'border-color': elemColors.TRIPADVISOR_BUSINESS,
      'background-image': tradivisor,
      label: '',
    },
  },
  {
    selector: 'node[type="YELP_BUSINESS"]',
    css: {
      'border-color': elemColors.YELP_BUSINESS,
      'background-image': yelp,
      label: '',
    },
  },
  {
    selector: 'node[type="LOCATION_GEOHASH"]',
    css: {
      'border-color': elemColors.LOCATION_GEOHASH,
      'background-image': locationGeohash,
      label: '',
    },
  },
  {
    selector: 'node[type="BAR_BUSINESS"]',
    css: {
      'border-color': elemColors.BAR_BUSINESS,
      'background-image': barBusiness,
      label: '',
    },
  },
  {
    selector: 'node[type="FOURSQUARE_BUSINESS"]',
    css: {
      'border-color': elemColors.FOURSQUARE_BUSINESS,
      'background-image': forsquare,
      label: '',
    },
  },
  {
    selector: 'node[type="WEEDMAPS_BUSINESS"]',
    css: {
      'border-color': elemColors.WEEDMAPS_BUSINESS,
      'background-image': weedmaps,
      label: '',
    },
  },
  {
    selector: 'node[type="OPENTABLE"]',
    css: {
      'border-color': elemColors.OPENTABLE,
      'background-image': opentable,
      label: '',
    },
  },
  {
    selector: 'node[type="DOC_LICENSE"]',
    css: {
      'border-color': elemColors.DOC_LICENSE,
      'background-image': license,
      label: '',
    },
  },
  {
    selector: '.added',
    css: {
      'border-color': '#78f094',
      'border-width': '6px',
    },
  },
  {
    selector: '.deleted',
    css: {
      'border-color': '#e69d9a',
      'border-width': '6px',
    },
  },
  {
    selector: '.mark',
    css: {
      'border-color': '#e9c46a',
      'border-width': '6px',
    },
  },
  {
    selector: 'node:selected',
    css: {
      'border-color': '#CDB4DB',
      'border-width': '8px',
    },
  },
  {
    selector: 'edge',
    css: {
      width: 0.4,
      'line-color': elemColors.EDGE,
      'target-arrow-color': elemColors.EDGE,
      'target-arrow-shape': 'triangle-backcurve',
      'arrow-scale': 0.5,
      'curve-style': 'bezier',
      'font-size': 2,
    },
  },
  {
    selector: 'edge:selected',
    css: {
      width: 1,
      'line-color': '#CDB4DB',
      'target-arrow-color': '#CDB4DB',
      'target-arrow-shape': 'triangle-backcurve',
      'font-size': 4,
    },
  },
  {
    selector: 'edge[type="labeled"]',
    css: {
      label: 'data(label)',
      'font-size': 12,
    },
  },
]

const alternateStyle: CSStylesheet[] = [
  {
    selector: 'node',
    css: {
      'font-size': 5,
      shape: 'ellipse',
      width: 25,
      height: 25,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-color': '#fafafa',
      'border-color': elemColors.OTHER,
      'border-width': '2px',
      'background-fit': 'cover',
      'background-image-containment': 'over',
      label: 'data(componentId_master.low)',
    },
  },
  {
    selector: 'node[type="FACEBOOK"]',
    css: {
      'border-color': elemColors.FACEBOOK,
    },
  },
  {
    selector: 'node[type="ETSY_SHOP"]',
    css: {
      'border-color': elemColors.ETSY_SHOP,
    },
  },
  {
    selector: 'node[type="AIRBNB"]',
    css: {
      'border-color': elemColors.AIRBNB,
    },
  },
  {
    selector: 'node[type="LOCATION"]',
    css: {
      'border-color': elemColors.LOCATION,
    },
  },
  {
    selector: 'node[type="TWITTER"]',
    css: {
      'border-color': elemColors.TWITTER,
    },
  },
  {
    selector: 'node[type="INSTAGRAM"]',
    css: {
      'border-color': elemColors.INSTAGRAM,
    },
  },
  {
    selector: 'node[type="OPENSEA_COLLECTION"]',
    css: {
      'border-color': elemColors.OPENSEA_COLLECTION,
    },
  },
  {
    selector: 'node[type="OPENSEA_ACCOUNT"]',
    css: {
      'border-color': elemColors.OPENSEA_ACCOUNT,
    },
  },
  {
    selector: 'node[type="LINKTREE"]',
    css: {
      'border-color': elemColors.LINKTREE,
    },
  },
  {
    selector: 'node[type="BLOCKCHAIN_WALLET"]',
    css: {
      'border-color': elemColors.BLOCKCHAIN_WALLET,
    },
  },
  {
    selector: 'node[type="WEBSITE"]',
    css: {
      'border-color': elemColors.WEBSITE,
    },
  },
  {
    selector: 'node[type="TELEGRAM"]',
    css: {
      'border-color': elemColors.TELEGRAM,
    },
  },
  {
    selector: 'node[type="PHONE"]',
    css: {
      'border-color': elemColors.PHONE_NUMBER,
    },
  },
  {
    selector: 'node[type="EMAIL"]',
    css: {
      'border-color': elemColors.EMAIL,
    },
  },
  {
    selector: 'node[type="LINKEDIN"]',
    css: {
      'border-color': elemColors.LINKEDIN,
    },
  },
  {
    selector: 'node[type="GMAPS"]',
    css: {
      'border-color': elemColors.GMAPS,
    },
  },

  {
    selector: 'node:selected',
    css: {
      'background-color': '#CDB4DB',
      opacity: 0.5,
    },
  },
  {
    selector: 'edge',
    css: {
      width: 0.4,
      'line-color': elemColors.EDGE,
      'target-arrow-color': elemColors.EDGE,
      'target-arrow-shape': 'triangle-backcurve',
      'arrow-scale': 0.5,
      'curve-style': 'bezier',
      'font-size': 2,
    },
  },
  {
    selector: 'edge:selected',
    css: {
      width: 1,
      'line-color': '#CDB4DB',
      'target-arrow-color': '#CDB4DB',
      'target-arrow-shape': 'triangle-backcurve',
      'font-size': 4,
    },
  },
]

export { defaultStyle, alternateStyle }
